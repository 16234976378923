import tc from './utils/table.js'
import fu from './utils/formUtils.js'

export const TableComp = tc
export const FormUtils = fu

export default {
  TableComp: tc,
  FormUtils: fu,
}


